// UserContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import { getAuth, onAuthStateChanged, signInAnonymously, signOut, linkWithCredential, EmailAuthProvider, signInWithEmailAndPassword } from 'firebase/auth';
import { collection, getDocs, doc, getDoc, setDoc, updateDoc, query, orderBy, deleteDoc, increment } from 'firebase/firestore';
import { FirebaseContext } from './Firebase';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const { auth, db } = useContext(FirebaseContext);
  const [user, setUser] = useState(null);
  const [totalCost, setTotalCost] = useState(0);
  const [totalTokens, setTotalTokens] = useState(0);
  const [sessionHistory, setSessionHistory] = useState([]);
  const [defaultSystemContext, setDefaultSystemContext] = useState(null);

  useEffect(() => {
    if (!auth) {
      console.error('Firebase auth is not initialized');
      return;
    }

    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        await checkAndLoadUser(currentUser.uid);
      } else {
        signInAnonymously(auth)
          .then(async (result) => {
            setUser(result.user);
            await checkAndLoadUser(result.user.uid);
          })
          .catch((error) => {
            console.error('Error signing in anonymously:', error);
          });
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const checkAndLoadUser = async (uid) => {
    const userDocRef = doc(db, 'users', uid);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      await updateDoc(userDocRef, { lastLoginAt: new Date() });
      const userData = userDoc.data();
      setTotalCost(userData.totalCost || 0);
      setTotalTokens(userData.totalTokens || 0);
      setUser((prevUser) => ({ ...prevUser, ...userData }));

      const systemContexts = Array.isArray(userData.systemContext) ? userData.systemContext : [];
      const activeContext = systemContexts.find((context) => context.isDefault) || systemContexts[systemContexts.length - 1] || { alias: '', context: '', isDefault: false };

      console.log('Initial Context:', activeContext);
      setDefaultSystemContext(activeContext);
    } else {
      await saveUserIfNotExist();
    }
    await fetchSessionHistory(uid);
  };

  const fetchSessionHistory = async (uid) => {
    const sessionsQuery = query(collection(db, 'users', uid, 'chats'), orderBy('timestamp', 'desc'));
    const sessionDocs = await getDocs(sessionsQuery);
    const sessionsData = sessionDocs.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setSessionHistory(sessionsData);
  };

  const saveUserIfNotExist = async () => {
    if (!user) return;
    const userDocRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userDocRef);
    if (!userDoc.exists()) {
      await setDoc(userDocRef, {
        uid: user.uid,
        displayName: user.displayName || null,
        email: user.email || null,
        emailVerified: user.emailVerified || false,
        isAnonymous: user.isAnonymous,
        photoURL: user.photoURL || null,
        createdAt: user.metadata.creationTime,
        lastLoginAt: user.metadata.lastSignInTime,
        totalCost: 0,
        totalTokens: 0,
        systemContext: [],
      });
    }
  };

  const updateUserDetails = async (currentUser) => {
    const userDocRef = doc(db, 'users', currentUser.uid);
    await updateDoc(userDocRef, {
      displayName: currentUser.displayName || null,
      email: currentUser.email || null,
      emailVerified: currentUser.emailVerified || false,
      isAnonymous: currentUser.isAnonymous,
      photoURL: currentUser.photoURL || null,
      lastLoginAt: new Date(),
      systemContext: currentUser.systemContext || [],
    });
    setUser(currentUser);
  };

  const updateTotalCostAndTokens = (cost, tokens) => {
    setTotalCost(prevCost => prevCost + cost);
    setTotalTokens(prevTokens => prevTokens + tokens);
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setUser(null);
      setSessionHistory([]);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const linkCredential = async (email, password) => {
    try {
      const credential = EmailAuthProvider.credential(email, password);
      await linkWithCredential(auth.currentUser, credential);
      await updateUserDetails(auth.currentUser);
      setUser(auth.currentUser);
    } catch (error) {
      console.error('Error linking credential:', error);
      if (error.code === 'auth/credential-already-in-use') {
        await signInWithEmailAndPassword(auth, email, password);
        await updateUserDetails(auth.currentUser);
        setUser(auth.currentUser);
        await clearUserSessions();
      }
    }
  };

  const clearUserSessions = async () => {
    if (user?.uid) {
      const sessionsRef = collection(db, 'users', user.uid, 'chats');
      const sessionDocs = await getDocs(sessionsRef);
      sessionDocs.forEach(async (doc) => {
        await deleteDoc(doc.ref);
      });
    }
    setTotalCost(0);
    setTotalTokens(0);
  };

  return (
    <UserContext.Provider value={{
      user,
      saveUserIfNotExist,
      totalCost,
      totalTokens,
      updateTotalCostAndTokens,
      updateUserDetails,
      handleSignOut,
      linkCredential,
      clearUserSessions,
      sessionHistory,
      fetchSessionHistory,
      defaultSystemContext,
      setDefaultSystemContext 
    }}>
      {children}
    </UserContext.Provider>
  );
};
