import React, { useState, useContext } from 'react';
import Modal from 'react-modal';
import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { UserContext } from '../contexts/User';
import '../styles/Settings.css';

Modal.setAppElement('#root');

const SignInModal = ({ isOpen, onRequestClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { linkCredential, clearUserSessions } = useContext(UserContext);
  const auth = getAuth();

  const handleSignIn = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      await linkCredential(email, password);
      onRequestClose();
    } catch (error) {
      console.error('Error signing in:', error);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
      await linkCredential(email, password);
      onRequestClose();
    } catch (error) {
      console.error('Error signing in with Google:', error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="auth-modal"
      overlayClassName="auth-modal-overlay"
    >
      <button className="close-button" onClick={onRequestClose}>&times;</button>
      <h3>Sign In</h3>
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={handleSignIn}>Sign In</button>
      <div className="or-divider">OR</div>
      <button onClick={handleGoogleSignIn}>Sign In with Google</button>
    </Modal>
  );
};

export default SignInModal;
