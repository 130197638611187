import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';  
import '../styles/Splash.css';

const Splash = () => {
  const navigate = useNavigate();
  const version = '1.0.8'; // Hardcoded version number

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/chat');
    }, 3000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="splash-container">
      <div className="version-text">Version {version}</div>
    </div>
  );
};

export default Splash;
