import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from '../contexts/User';
import '../styles/SystemContextModal.css';

const SystemContextModal = ({ isOpen, onClose }) => {
  const { user, updateUserDetails, defaultSystemContext, setDefaultSystemContext } = useContext(UserContext);
  const [alias, setAlias] = useState('');
  const [systemContext, setSystemContext] = useState('');
  const [isDefault, setIsDefault] = useState(false);
  const [contexts, setContexts] = useState([]);

  useEffect(() => {
    if (user && Array.isArray(user.systemContext)) {
      setContexts(user.systemContext);
    } else {
      setContexts([]);
    }
  }, [user]);

  // Maintain session persistence for active context
  useEffect(() => {
    if (isOpen) {
      const currentContext = defaultSystemContext || { alias: '', context: '', isDefault: false };
      setAlias(currentContext.alias);
      setSystemContext(currentContext.context);
      setIsDefault(currentContext.isDefault);
    }
  }, [isOpen, defaultSystemContext]);

  const handleSave = async () => {
    if (!user) {
      console.error("User is not available");
      return;
    }
  
    const newContext = { alias, context: systemContext, isDefault };
    const existingIndex = contexts.findIndex((c) => c.alias === alias);
  
    let updatedContexts = [...contexts];
  
    if (existingIndex !== -1) {
      // Update the existing context
      updatedContexts[existingIndex] = newContext;
    } else {
      // Add new context
      updatedContexts.push(newContext);
    }
  
    if (isDefault) {
      // Set all other contexts' isDefault to false
      updatedContexts = updatedContexts.map((c) =>
        c.alias === alias ? { ...c, isDefault: true } : { ...c, isDefault: false }
      );
    }
  
    console.log('Updated Contexts:', updatedContexts);
  
    try {
      // Update the user's contexts in Firestore and local state
      await updateUserDetails({ ...user, systemContext: updatedContexts });
      setContexts(updatedContexts);
  
      // Update the active context for the session
      const activeContext = updatedContexts.find((c) => c.alias === alias);
      setDefaultSystemContext(activeContext); // Set current context as active
  
      onClose();
    } catch (error) {
      console.error('Error updating user details:', error);
    }
  };
  
  const handleCancel = () => {
    onClose();
  };

  const handleSelectChange = (e) => {
    const selectedContext = contexts.find((c) => c.alias === e.target.value);
    if (selectedContext) {
      setAlias(selectedContext.alias || '');
      setSystemContext(selectedContext.context || '');
      setIsDefault(selectedContext.isDefault || false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h2>Your Custom Contexts</h2>
        <p className="modal_leadtext">
          Enter your personal custom context here; preferences, important information to remember, etc. It will be passed along to every chat session.
        </p>
        <div className="input-group contextSelect">
          <label htmlFor="contextSelect">Select Existing Context:</label>
          <select id="contextSelect" onChange={handleSelectChange} value={alias}>
            <option value="">
              {contexts.length > 0 ? 'Select a context' : 'No contexts available'}
            </option>
            {contexts.map((c, index) => (
              <option key={index} value={c.alias}>
                {c.alias}
              </option>
            ))}
          </select>
        </div>
        <div className="input-group">
          <label htmlFor="aliasInput">Alias:</label>
          <input
            id="aliasInput"
            type="text"
            value={alias}
            onChange={(e) => setAlias(e.target.value)}
            placeholder="Enter an alias for your context"
          />
        </div>
        <textarea
          className="contextText"
          value={systemContext}
          onChange={(e) => setSystemContext(e.target.value)}
          placeholder="e.g; Reply only as Yoda"
        />
        <div className="input-group checkbox-group">
          <input
            id="defaultCheckbox"
            type="checkbox"
            checked={isDefault}
            onChange={(e) => setIsDefault(e.target.checked)}
          />
          <label htmlFor="defaultCheckbox">Set as default system context</label>
        </div>
        <div className="modal-buttons">
          <button onClick={handleCancel}>Cancel</button>
          <button onClick={handleSave}>Save</button>
        </div>
      </div>
    </div>
  );
};

export default SystemContextModal;
