import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Splash from './views/Splash';  
import Chat from './views/Chat';
import History from './views/History';
import Settings from './views/Settings';
import Admin from './views/Admin';

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Splash />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/history" element={<History />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/admin" element={<Admin />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
