// util.js
export const calculateCost = (tokens, rate) => (tokens / 1e6) * rate;

export const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split(',')[1]);
        reader.onerror = (error) => reject(error);
    });
};

export const getMediaType = (file) => {
    return file.type;
};

export const copyToClipboard = (text, onSuccess, onError) => {
  navigator.clipboard.writeText(text).then(() => {
    if (onSuccess) onSuccess();
  }).catch(err => {
    if (onError) onError(err);
  });
};

export const rates = {
    openai: { input: 5, output: 15, alias: 'GPT 4o' },
    claude: { input: 3, output: 15, alias: 'Claude 3.5 Sonnet' },
    gemini: { input: 3.5, output: 10.5, alias: 'Gemini 1.5 Pro' },
    llama: { input: 3.2, output: 3.2, alias: 'Llama 3.1' }
};
