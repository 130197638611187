import React, { createContext, useState, useContext, useEffect } from 'react';
import { collection, doc, setDoc, getDoc, deleteDoc, updateDoc, increment } from 'firebase/firestore';
import { FirebaseContext } from './Firebase';
import { UserContext } from './User';
import axios from 'axios';

export const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const { db } = useContext(FirebaseContext);
  const { user, saveUserIfNotExist, updateTotalCostAndTokens } = useContext(UserContext);
  const [sessionTitle, setSessionTitle] = useState('New Chat Session');
  const [session_title_summary, setSessionTitleSummary] = useState('New Chat Session');
  const [isSessionTitleManuallyUpdated, setIsSessionTitleManuallyUpdated] = useState(false); 
  const [contextWindow, setContextWindow] = useState([]);
  const [totalCost, setTotalCost] = useState(0);
  const [totalTokens, setTotalTokens] = useState(0);
  const [chatId, setChatId] = useState(null);
  const [apiContext, setApiContext] = useState([]);
  const [highestInteractionId, setHighestInteractionId] = useState(0);
  const [selectedModel, setSelectedModel] = useState('openai');
  const [summarizeTokenThreshold, setSummarizeTokenThreshold] = useState(60000);
  const [isSummarizing, setIsSummarizing] = useState(false); 

  useEffect(() => {
    if (!chatId && user) {
      const newChatRef = doc(collection(doc(db, 'users', user.uid), 'chats'));
      setChatId(newChatRef.id);
      setHighestInteractionId(0); // Initialize highest interaction ID for new chat
    }
  }, [db, chatId, user]);

  const calculateTotalTokens = () => {
    return contextWindow.reduce((acc, curr) => {
      if (curr.inputTokens && curr.outputTokens) {
        return acc + curr.inputTokens + curr.outputTokens;
      }
      return acc;
    }, 0);
  };

  const calculateTotalCost = () => {
    return contextWindow.reduce((acc, curr) => {
      if (curr.inputCost && curr.outputCost) {
        return acc + parseFloat(curr.inputCost) + parseFloat(curr.outputCost);
      }
      return acc;
    }, 0);
  };

  const saveChatSession = async () => {
    if (!user || !chatId) return;
  
    await saveUserIfNotExist();
  
    const userDocRef = doc(db, 'users', user.uid);
    const chatDocRef = doc(userDocRef, 'chats', chatId);
  
    const totalTokens = calculateTotalTokens();
    const totalCost = calculateTotalCost();
  
    const chatDoc = await getDoc(chatDocRef);
    const dataToUpdate = {
      sessionTitle,
      totalCost,
      totalTokens
    };
  
    if (isSessionTitleManuallyUpdated) {
      console.log("if this was manually updated, vs auto, then set extra flag");
      dataToUpdate.isManualTitle = true;
    }
  
    if (!chatDoc.exists()) {
      await setDoc(chatDocRef, {
        ...dataToUpdate,
        timestamp: new Date()
      });
    } else {
      await updateDoc(chatDocRef, dataToUpdate);
    }
  };  

  const addInteraction = async (interaction) => {
    if (!user || !chatId) return;

    await saveUserIfNotExist();

    const userDocRef = doc(db, 'users', user.uid);
    const chatDocRef = doc(userDocRef, 'chats', chatId);

    const chatDoc = await getDoc(chatDocRef);
    if (!chatDoc.exists()) {
      await setDoc(chatDocRef, {
        sessionTitle,
        timestamp: new Date(),
        totalCost,
        totalTokens: calculateTotalTokens()
      });
    }

    const newInteractionId = (highestInteractionId + 1).toString();
    setHighestInteractionId(highestInteractionId + 1); // Increment the highest interaction ID

    const interactionsCollectionRef = collection(chatDocRef, 'interactions');
    const interactionDocRef = doc(interactionsCollectionRef, newInteractionId);
    await setDoc(interactionDocRef, { ...interaction, id: newInteractionId });

    const inputCost = parseFloat(interaction.inputCost);
    const outputCost = parseFloat(interaction.outputCost);
    const inputTokens = interaction.inputTokens;
    const outputTokens = interaction.outputTokens;

    // Update the chat document
    await updateDoc(chatDocRef, {
      totalCost: increment(inputCost + outputCost),
      totalTokens: increment(inputTokens + outputTokens),
    });

    // Update the top-level user document
    await updateDoc(userDocRef, {
      totalCost: increment(inputCost + outputCost),
      totalTokens: increment(inputTokens + outputTokens),
    });

    // Update the local state with the new interaction ID
    setContextWindow(prev => prev.map(item => {
      if (item.temp === "butthead") {
        const newItem = { ...item, id: newInteractionId };
        delete newItem.temp; // Remove the temp property
        return newItem;
      }
      return item;
    }));

    setApiContext(prev => prev.map(item => {
      if (item.temp === "butthead") {
        const newItem = { ...item, id: newInteractionId };
        delete newItem.temp; // Remove the temp property
        return newItem;
      }
      return item;
    }));

    setTotalTokens(prevTokens => prevTokens + inputTokens + outputTokens); // Update total tokens
    setTotalCost(prevCost => prevCost + inputCost + outputCost); // Update total cost

    // Update UserContext state
    updateTotalCostAndTokens(inputCost + outputCost, inputTokens + outputTokens);
  };

  const deleteInteraction = async (interactionId) => {
    if (!user || !chatId) return;

    const userDocRef = doc(db, 'users', user.uid);
    const chatDocRef = doc(userDocRef, 'chats', chatId);
    const interactionsCollectionRef = collection(chatDocRef, 'interactions');
    const interactionDocRef = doc(interactionsCollectionRef, interactionId);

    await deleteDoc(interactionDocRef);

    // Update the local state
    setContextWindow(prev => prev.filter(item => item.id !== interactionId));
    setApiContext(prev => prev.filter(item => item.id !== interactionId));
  };

  const transformInteractions = (interactions, isManualTitle) => {
    // Sort interactions based on the id
    const sortedInteractions = interactions.sort((a, b) => parseInt(a.id) - parseInt(b.id));
  
    const highestId = Math.max(...sortedInteractions.map(interaction => parseInt(interaction.id)), 0);
    setHighestInteractionId(highestId);
  
    const apiContext = sortedInteractions.reduce((acc, interaction) => {
      acc.push(
        { role: 'user', content: interaction.user_content, id: interaction.id },
        { role: 'assistant', content: interaction.assistant_content, id: interaction.id }
      );
      return acc;
    }, []);
  
    const contextWindow = sortedInteractions.reduce((acc, interaction) => {
      const userContent = Array.isArray(interaction.user_content) ? interaction.user_content : [{ type: 'text', text: interaction.user_content }];
      acc.push({ role: 'user', content: userContent, id: interaction.id });
  
      const assistantContent = Array.isArray(interaction.assistant_content) ? interaction.assistant_content : [{ type: 'text', text: interaction.assistant_content }];
      acc.push({
        role: 'assistant',
        content: assistantContent,
        inputTokens: interaction.inputTokens,
        outputTokens: interaction.outputTokens,
        inputCost: interaction.inputCost,
        outputCost: interaction.outputCost,
        model: interaction.model,
        id: interaction.id
      });
      return acc;
    }, []);
  
    setApiContext(apiContext);
    setContextWindow(contextWindow);

    // Set session title manually updated flag if isManualTitle is true
    if (isManualTitle) {
      setIsSessionTitleManuallyUpdated(true);
    }

    // Check if summarization is needed
    let totalTokens = sortedInteractions.reduce((acc, interaction) => {
      return acc + (interaction.inputTokens || 0) + (interaction.outputTokens || 0);
    }, 0);
    checkAndSummarizeApiContext(totalTokens, apiContext, contextWindow);
  
    console.log('transformInteractions(interactions)', interactions);
    console.log('Transformed apiContext:', apiContext);
    console.log('Transformed contextWindow:', contextWindow);
  };

  const checkAndSummarizeApiContext = async (totalTokens, apiContext, contextWindow) => {
    console.log("in checkAndSummarizeApiContext totalTokens >= summarizeTokenThreshold", totalTokens, summarizeTokenThreshold, totalTokens >= summarizeTokenThreshold);
    return;
    if (totalTokens >= summarizeTokenThreshold) {
      await summarizeAndTruncateApiContext(apiContext, contextWindow);
    }
  };

  const summarizeAndTruncateApiContext = async (apiContext, contextWindow) => {
    try {
      setIsSummarizing(true); // Set isSummarizing to true
      const maxTokens = 20000;
      let tokenCount = 0;
      let sliceIndex = 0;
  
      // console.log("Starting token counting...");
      for (let i = 0; i < contextWindow.length; i++) {
        if(contextWindow[i]?.role !== 'assistant'){
          continue;
        }
        const userTokens = contextWindow[i]?.inputTokens || 0;
        const assistantTokens = contextWindow[i]?.outputTokens || 0;
        tokenCount += userTokens + assistantTokens;

        // console.log(`Interaction ${i}: User Tokens = ${userTokens}, Assistant Tokens = ${assistantTokens}, Total Token Count = ${tokenCount}`);

        if (tokenCount > maxTokens) {
          sliceIndex = i + 1; // Calculate the index in apiContext
          break;
        }
      }

      // Ensure the slice ends at a complete set of user and assistant interactions
      while (sliceIndex < apiContext.length && apiContext[sliceIndex].role !== 'assistant') {
        sliceIndex++;
      }
  
      if (sliceIndex < apiContext.length) {
        sliceIndex++; // Include the assistant response in the slice
      }
  
      // console.log(`Calculated sliceIndex: ${sliceIndex}`);
  
      let summarizeSlice = apiContext.slice(0, sliceIndex);
      console.log("Summarize slice:", summarizeSlice);
  
      // Prepare the prompt for the AI
      const prompt = 'Please provide a concise summary of the chatML conversation and existing system Context if any:';
  
      // Create the request payload for the summarization API
      const summaryRequest = {
        model: selectedModel, // Use the selected model if needed
        context: [...summarizeSlice, { role: 'system', content: prompt }],
      };
  
      // Call the summarization API using the same endpoint
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/chat`, summaryRequest, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      // Log the summary from the API response
      const summary = response.data.response_text;
      console.log("Summary from the API:", summary);
  
      // Update the apiContext with the new truncated context and summary
      const remainingContext = apiContext.slice(sliceIndex);
      const newSystemMessage = { role: 'system', content: [{type:"text", text:summary}] };
      remainingContext.unshift(newSystemMessage);
  
      // Update the apiContext with the new truncated context
      setApiContext(remainingContext);
      console.log('apiContext truncated and summarized', remainingContext);
    } catch (error) {
      console.error('Error summarizing and truncating apiContext:', error);
    } finally {
      setIsSummarizing(false); // Set isSummarizing to false
    }
  };
  
  return (
    <ChatContext.Provider value={{ 
      sessionTitle, 
      setSessionTitle, 
      isSessionTitleManuallyUpdated, 
      setIsSessionTitleManuallyUpdated,
      session_title_summary, 
      setSessionTitleSummary,
      contextWindow, 
      setContextWindow, 
      totalCost, 
      setTotalCost, 
      totalTokens, 
      setTotalTokens, 
      addInteraction, 
      chatId, 
      setChatId, 
      saveChatSession, 
      apiContext, 
      setApiContext, 
      setHighestInteractionId, 
      deleteInteraction, 
      transformInteractions,
      checkAndSummarizeApiContext,
      isSummarizing 
    }}>
      {children}
    </ChatContext.Provider>
  );
};
