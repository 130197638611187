import React, { useState, useContext } from 'react';
import { UserContext } from '../contexts/User';
import { FaHistory, FaComments } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import SignInModal from '../components/SigninModal';
import '../styles/Settings.css';

const Settings = () => {
  const { user, handleSignOut } = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleSignInSignUpClick = () => {
    if (user && !user.isAnonymous) {
      handleSignOut();
    } else {
      setShowModal(true);
    }
  };

  return (
    <div className="settings-container">
      <div className="settings-header">
        <div className={`chat-nav`}>
            <FaComments className="history-icon" onClick={() => navigate('/chat')} />
        </div>
        <h2>Session Archives</h2>
      </div>
      <div className="settings-content">
        <div className="user-info">
          <p>Display Name: {user?.displayName || 'Anonymous'}</p>
          <p>Email: {user?.email || 'Anonymous'}</p>
          <button className="sign-in-button" onClick={handleSignInSignUpClick}>
            {user && !user.isAnonymous ? 'Sign Out' : 'Sign In / Sign Up'}
          </button>
        </div>
        <div className="settings-info">
          <h3>About the App</h3>
          <p>
            This app is designed to help you manage your chat sessions effectively. You can start new sessions, view past sessions, and maintain your context while interacting with different AI models.
          </p>
          <p>
            Coming to the site, you will automatically be signed in as an Anonymous user. This will allow you 1$ of AI usage total. Look in your "History" to see what your current total is.
          </p>
          <p>
            If you "upgrade" your account by creating a login with an email/password OR with Google Sign in. Your usage limit will be increased to 5$. To get more, you will need to contact Uncle Irvin.
          </p>
        </div>
      </div>
      <SignInModal isOpen={showModal} onRequestClose={() => setShowModal(false)} />
    </div>
  );
};

export default Settings;
