import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, orderBy, query, doc, deleteDoc, writeBatch } from 'firebase/firestore';
import { FirebaseContext } from '../contexts/Firebase';
import { UserContext } from '../contexts/User';
import { ChatContext } from '../contexts/Chat';
import { FaCog, FaComments, FaTrash } from 'react-icons/fa';
import ReactConfetti from 'react-confetti';
import '../styles/History.css';

const History = () => {
  const { db } = useContext(FirebaseContext);
  const { user, sessionHistory, fetchSessionHistory, totalCost, totalTokens } = useContext(UserContext); // Use totalCost and totalTokens from UserContext
  const { setChatId, transformInteractions, setSessionTitle, setTotalCost, setTotalTokens } = useContext(ChatContext);
  const navigate = useNavigate();
  const [confetti, setConfetti] = useState(false);

  useEffect(() => {
    if (user) {
      fetchSessionHistory(user.uid);
    }
  }, [user, fetchSessionHistory]);

  const loadChatInteractions = async (uid, chatId) => {
    const interactionsRef = collection(db, 'users', uid, 'chats', chatId, 'interactions');
    const interactionsQuery = query(interactionsRef, orderBy('__name__'));
    const interactionsSnapshot = await getDocs(interactionsQuery);
    const interactions = interactionsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    // console.log('loadChatInteractions():', interactions);
    return interactions;
  };

  const handleSessionClick = async (sessionId) => {
    const interactions = await loadChatInteractions(user.uid, sessionId);
    const chat = sessionHistory.find(chat => chat.id === sessionId);

    console.log("ok clicking on chatId ", sessionId, chat);
    if (chat && interactions) {
      setChatId(sessionId);
      setSessionTitle(chat.sessionTitle);
      setTotalCost(chat.totalCost);
      setTotalTokens(chat.totalTokens);
      
      const isManualTitle = chat.isManualTitle || false;
      transformInteractions(interactions, isManualTitle);
    }
    navigate('/chat');
  };

  const handleDeleteSession = async (sessionId) => {
    if (!user) return;

    const userDocRef = doc(db, 'users', user.uid);
    const chatDocRef = doc(userDocRef, 'chats', sessionId);

    // Delete interactions sub-collection
    const interactionsRef = collection(chatDocRef, 'interactions');
    const interactionsSnapshot = await getDocs(interactionsRef);
    const batch = writeBatch(db);

    interactionsSnapshot.docs.forEach(doc => {
      batch.delete(doc.ref);
    });

    await batch.commit();

    // Delete the chat document
    await deleteDoc(chatDocRef);

    // Refresh session history
    await fetchSessionHistory(user.uid);
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000);
    return date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
  };

  const groupSessionsByDate = (sessions) => {
    return sessions.reduce((groups, session) => {
      const date = formatTimestamp(session.timestamp);
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(session);
      return groups;
    }, {});
  };

  const groupedSessions = groupSessionsByDate(sessionHistory);

  return (
    <div className="history-container">
      {confetti && <ReactConfetti recycle={false} numberOfPieces={500} />}
      <div className="history-header">
        <div className={`chat-nav`}>
          <FaComments className="history-icon" onClick={() => navigate('/chat')} />
        </div>
        <h2>Session Archives</h2>
        <div className="history-metrics" onClick={() => setConfetti(true)}>
          <span className="total-tokens">All Time Tokens: {totalTokens}</span>
          <span className="total-cost">All Time Cost: ${totalCost.toFixed(6)}</span>
        </div>
      </div>
      <div className="history-messages">
        {Object.keys(groupedSessions).map(date => {
          const dayTokens = groupedSessions[date].reduce((acc, session) => acc + (session.totalTokens || 0), 0);
          const dayCost = groupedSessions[date].reduce((acc, session) => acc + (session.totalCost || 0), 0).toFixed(6);

          return (
            <div className={`history-date-group`} key={date}>
              <div className="history-date-header">
                <h3>{date}</h3>
                <div className="day_metrics">
                  <span>Sum Tokens (Day): {dayTokens}</span>
                  <span>Sum Cost (Day): ${dayCost}</span>
                </div>
              </div>
              <hr className="history-hr" />
              {groupedSessions[date].map((session, index) => (
                <div key={index} className="history-message-group">
                  <div className="history-message">
                    <div className="history-message-content">
                      <strong onClick={() => handleSessionClick(session.id)}>{session.sessionTitle}</strong>
                      <div className="history-session-meta">
                        <div className="meta-info">
                          <span>Tokens: {session.totalTokens || 0}</span>
                          <span>Cost: ${session.totalCost.toFixed(6) || '0.000000'}</span>
                        </div>
                        <FaTrash className="delete-icon" onClick={() => handleDeleteSession(session.id)} />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default History;
