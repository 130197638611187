// views/Admin.js
import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../contexts/User';
import { FirebaseContext } from '../contexts/Firebase';
import { FaComments } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import '../styles/Settings.css';
import { lottieAnimations , LottieAnimation} from '../components/LottieAnimations';

const Admin = () => {
  const navigate = useNavigate();
  const { db } = useContext(FirebaseContext);
  const { user } = useContext(UserContext);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (user) {
      user.getIdTokenResult().then((idTokenResult) => {
        if (idTokenResult.claims.role !== "admin") {
          navigate('/chat');
        } else {
          console.log('Custom Claims:', idTokenResult.claims);
        }
      }).catch((error) => {
        console.error('Error fetching ID token result:', error);
        navigate('/chat'); // Redirect if there's an error fetching token result
      });

      const fetchUsers = async () => {
        const usersCollection = collection(db, 'users');
        const usersSnapshot = await getDocs(usersCollection);
        const usersList = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setUsers(usersList);
      };

      fetchUsers();
    } else {
      console.log('No user is currently logged in.');
      // navigate('/chat');
    }
  }, [user, db, navigate]);

  return (
    <div className="settings-container">
      <div className="settings-header">
        <div className="chat-nav">
          <FaComments className="history-icon" onClick={() => navigate('/chat')} />
        </div>
        <h2>Admin</h2>
      </div>
      <div className="history-messages"> {/* Use history-messages to match History.js styling */}
        <div className="history-date-group">
          <div className="history-date-header">
            <h3>User Costs</h3>
          </div>
          <hr className="history-hr" />
          {users.length > 0 ? (
            users.map((user, index) => (
              <div key={index} className="history-message-group">
                <div className="history-message">
                  <div className="history-message-content">
                    <strong>{user.isAnonymous ? user.id : user.displayName || user.email}</strong>
                    <div className="history-session-meta">
                      <div className="meta-info">
                        <span>Total Tokens: {user.totalTokens || 0}</span>
                        <span>Total Cost: ${(user.totalCost || 0).toFixed(6)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No users found.</p>
          )}
        </div>
      </div>
      <div className="lottie-container">
        {lottieAnimations.map((animationData, index) => (
          <LottieAnimation key={index} animationData={animationData} />
        ))}
      </div>
    </div>
  );
};

export default Admin;
