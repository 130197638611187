import React, { useState, useContext, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import debounce from 'lodash/debounce';
import { FirebaseContext } from '../contexts/Firebase';
import { ChatContext } from '../contexts/Chat';
import { UserContext } from '../contexts/User';
import ReactMarkdown from 'react-markdown';
import '../styles/Chat.css';
import { FaHistory, FaCog, FaPlus, FaTrash, FaPaperclip, FaStop, FaMicrophone } from 'react-icons/fa';
import { BsCardText } from "react-icons/bs";
import { doc, collection } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useTextSelection from '../components/useTextSelection';
import { calculateCost, getBase64, getMediaType, copyToClipboard, rates } from '../components/util';
import { getRandomAnimationData, LottieAnimation } from '../components/LottieAnimations';
import playTextToSpeech from '../components/TextToSpeech';
import VoiceRecorder from '../components/VoiceRecorder';
import SystemContextModal from '../components/SystemContextModal';

const Chat = () => {
  const { db, storage } = useContext(FirebaseContext);
  const { user, totalCost, defaultSystemContext } = useContext(UserContext);  // Access defaultSystemContext here
  const {
    sessionTitle,
    setSessionTitle,
    contextWindow,
    setContextWindow,
    totalCost: sessionTotalCost,
    setTotalCost: setSessionTotalCost,
    addInteraction,
    chatId,
    setChatId,
    saveChatSession,
    apiContext,
    setApiContext,
    deleteInteraction,
    totalTokens,
    setTotalTokens,
    setHighestInteractionId,
    checkAndSummarizeApiContext,
    isSummarizing,
    isSessionTitleManuallyUpdated, 
    setIsSessionTitleManuallyUpdated,
    session_title_summary, 
    setSessionTitleSummary
  } = useContext(ChatContext);

  const navigate = useNavigate();
  const voiceRecorderRef = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [respondWithAudio, setRespondWithAudio] = useState(true);
  const [isTranscribing, setIsTranscribing] = useState(false);
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [currentQuery, setCurrentQuery] = useState('');
  const [selectedModel, setSelectedModel] = useState('openai');
  const [files, setFiles] = useState([]);
  const [dragOver, setDragOver] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const [isSystemContextModalOpen, setIsSystemContextModalOpen] = useState(false);

  const anonUsageLimit = 1;
  const userUsageLimit = 50;

  const anonUsageLimitCondition = user?.isAnonymous && totalCost > anonUsageLimit;
  const userUsageLimitCondition = !user?.isAnonymous && totalCost > userUsageLimit;
  const usageLimitCondition = anonUsageLimitCondition || userUsageLimitCondition;

  const openSystemContextModal = () => setIsSystemContextModalOpen(true);
  const closeSystemContextModal = () => setIsSystemContextModalOpen(false);

  useEffect(() => {
    if (!chatId && user) {
      const newChatRef = doc(collection(doc(db, 'users', user.uid), 'chats'));
      setChatId(newChatRef.id);
    }
  }, [db, chatId, setChatId, user]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [contextWindow]);

  useEffect(() => {
    if (!isSessionTitleManuallyUpdated) {
      setSessionTitle(session_title_summary);
    }
  }, [session_title_summary, isSessionTitleManuallyUpdated]);
  
  useEffect(() => {
    if (!isSessionTitleManuallyUpdated && sessionTitle !== 'New Chat Session') {
      saveChatSession();
    }
  }, [sessionTitle, isSessionTitleManuallyUpdated]);

  const handleWordSelectionComplete = async (selectedText, type) => {
    console.log(`${type.charAt(0).toUpperCase() + type.slice(1)} selected:`, selectedText);
    console.log("lets open a modal/ tool tip");
  };

  const handleSentenceSelectionComplete = async (selectedText, type) => {
    console.log(`${type.charAt(0).toUpperCase() + type.slice(1)} selected:`, selectedText);
    await playTextToSpeech(selectedText, 'random');
  };
      
  useTextSelection(".message-group", handleWordSelectionComplete, handleSentenceSelectionComplete);

  const handleStartRecording = () => {
    voiceRecorderRef.current.startRecording();
    setIsRecording(true);
  };

  const handleStopRecording = async () => {
    setIsTranscribing(true);
  
    try {
      await voiceRecorderRef.current.stopRecording();
      const transcription = await voiceRecorderRef.current.getTranscription();
      if (transcription) {
        handleSendQuery(transcription, true);
      } else {
        setIsTranscribing(false);  // Reset transcribing state here if no transcription
      }
    } catch (error) {
      console.error("Error stopping recording and getting transcription:", error);
      setIsTranscribing(false);  // Reset transcribing state here in case of error
    }
  };

  const handleTitleClick = () => {
    setIsEditingTitle(true);
  };

  const handleTitleChange = (e) => {
    setSessionTitle(e.target.value);
  };

  const handleTitleBlur = async () => {
    setIsEditingTitle(false);
    setIsSessionTitleManuallyUpdated(true);
    await saveChatSession();
  };

  const handleDeleteInteraction = async (interactionId) => {
    await deleteInteraction(interactionId);
  };

  const handleNewChat = () => {
    const newChatRef = doc(collection(doc(db, 'users', user.uid), 'chats'));
    setChatId(newChatRef.id);
    setSessionTitle('New Chat Session');
    setContextWindow([]);
    setSessionTotalCost(0);
    setTotalTokens(0);
    setApiContext([]);
    setHighestInteractionId(0);
  };

  const groupMessages = () => {
    const groups = [];
    let currentGroup = [];

    contextWindow.forEach((message, index) => {
      currentGroup.push(message);
      if (message.role === 'assistant' || index === contextWindow.length - 1) {
        groups.push(currentGroup);
        currentGroup = [];
      }
    });

    return groups.filter(group => group.length > 0);
  };

  const renderCodeContent = ({ children }) => (
    <div className="code-container">
      <pre>
        <code>{children}</code>
      </pre>
      <button className="copy-button" onClick={() => copyToClipboard(children,
        () => toast.success('Code copied to clipboard'), 
        (err) => toast.error('Failed to copy code to clipboard'))}>Copy</button>
    </div>
  );

  const renderMessageContent = (content) => {
    if (Array.isArray(content)) {
      const textElements = [];
      const imageElements = [];
  
      content.forEach((item, index) => {
        if (item.type === "text") {
          if (typeof item.text === 'string') {
            const codeRegex = /(```[\s\S]*?```)|(<code[\s\S]*?<\/code>)/g;
            const parts = item.text.split(codeRegex);
            textElements.push(
              <div key={`text-${index}`}>
                {parts.map((part, idx) => {
                  if (codeRegex.test(part)) {
                    const cleanedPart = part.replace(/```/g, '').replace(/<\/?code>/g, '');
                    return renderCodeContent({ children: cleanedPart });
                  }
                  return <ReactMarkdown key={idx}>{part}</ReactMarkdown>;
                })}
              </div>
            );
          } else {
            console.warn(`Unexpected value for text: ${JSON.stringify(item.text)}`);
          }
        } else if (item.type === "image_url") {
          imageElements.push(
            <img 
              key={`image-${index}`} 
              src={item.image_url.url} 
              alt="Image Thumbnail"
              onClick={() => window.open(item.image_url.url, '_blank')}
            />
          );
        } else if (item.type === "animation") {
          textElements.push(
            <LottieAnimation
              key={`animation-${index}`}
              animationData={item.animationData}
              height={200}
              width={200}
            />
          );
        } else {
          console.warn(`Unexpected value for content item: ${JSON.stringify(item)}`);
        }
      });
  
      return (
        <div className="message-container">
          {imageElements.length > 0 && <div className="image-thumbnails">{imageElements}</div>}
          {textElements}
        </div>
      );
    } else if (content && content.type === 'animation') {
      return (
        <div className="message-container loading_animation">
          <LottieAnimation animationData={content.animationData} />
        </div>
      );
    } else if (typeof content === 'string') {
      const codeRegex = /(```[\s\S]*?```)|(<code[\s\S]*?<\/code>)/g;
      const parts = content.split(codeRegex);
      return (
        <div className="message-container">
          {parts.map((part, idx) => {
            if (codeRegex.test(part)) {
              const cleanedPart = part.replace(/```/g, '').replace(/<\/?code>/g, '');
              return renderCodeContent({ children: cleanedPart });
            }
            return <ReactMarkdown key={idx}>{part}</ReactMarkdown>;
          })}
        </div>
      );
    } else {
      console.warn(`Unexpected value for content: ${JSON.stringify(content)}`);
      return null;
    }
  };
  
  const uploadFilesAndGetUrls = async (files, userId, storage) => {
    if (!Array.isArray(files)) files = Array.from(files);
    const fileUrls = await Promise.all(files.map(async (file) => {
      const storageRef = ref(storage, `${userId}/${file.name}`);
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);
      return {
        type: "image_url",
        image_url: { url },
        file
      };
    }));
    return fileUrls;
  };

  const handleFileDrop = async (droppedFiles) => {
    const newFiles = Array.from(droppedFiles);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    await handleFileUpload(newFiles);
    setDragOver(false);
  };

  const handleFileUpload = async (files) => {
    const uploadedFileUrls = await uploadFilesAndGetUrls(files, user.uid, storage);
    setUploadedFiles((prevUrls) => [...prevUrls, ...uploadedFileUrls]);
  };

  const handleFileChange = async (e) => {
    const newFiles = Array.from(e.target.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    await handleFileUpload(newFiles);
  };

  const handleRemoveFile = (index) => {
    setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    setUploadedFiles(prevUrls => prevUrls.filter((_, i) => i !== index));
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendQuery();
    }
  };

  const handleQueryChange = (e) => {
    setCurrentQuery(e.target.value);
  };

  const handleModelChange = (e) => {
    setSelectedModel(e.target.value);
  };

  const handleButtonClick = () => {
    if (!isSendButtonDisabled) {
      setIsSendButtonDisabled(true);
      handleSendQuery();
      // debouncedHandleSendQuery();
    }
  };

  const debouncedHandleSendQuery = useCallback(
    debounce(() => {
      handleSendQuery();
    }, 300),
    []
  );

  const handleSendQuery = async (manualQuery = null, wasAudioInput = false) => {
    if (usageLimitCondition) {
      return;
    }
    
    const queryToSend = manualQuery !== null ? manualQuery : currentQuery.trim();
    if (!queryToSend && files.length === 0) {
      toast.error("Please type your message or attach a file first");
      return;
    }

    try {
      setIsLoading(true);
      setIsRecording(false);
      setIsTranscribing(false); 

      let contentStructure = [{ type: "text", text: queryToSend }];

      if (uploadedFiles.length > 0) {
        contentStructure = contentStructure.concat(uploadedFiles.map(fileObj => ({
          type: "image_url",
          image_url: { url: fileObj.image_url.url }
        })));
      }

      const userMessage = { role: 'user', content: contentStructure, temp: 'butthead' };
      const newApiContext = [...apiContext, userMessage];
      setApiContext(newApiContext);
      setContextWindow([...contextWindow, userMessage]);
      setCurrentQuery('');

      let contextForApi = [...newApiContext];
      if (contextForApi.length > 0 && contextForApi[0].role !== 'user') {
        contextForApi.unshift({ role: 'user', content: contextForApi.shift().content });
      }

      const placeholderMessage = {
        role: 'assistant',
        content: { type: 'animation', animationData: getRandomAnimationData() },
        temp: 'placeholder'
      };
      setContextWindow(prev => [...prev, placeholderMessage]);

      if (selectedModel === 'claude') {
        const lastUserMessage = JSON.parse(JSON.stringify(contextForApi[contextForApi.length - 1]));
        lastUserMessage.content = await Promise.all(lastUserMessage.content.map(async (item) => {
          if (item.type === "image_url") {
            const fileObj = uploadedFiles.find(fileObj => fileObj.image_url.url === item.image_url.url);
            const base64Data = await getBase64(fileObj.file);
            return {
              type: "image",
              source: {
                type: "base64",
                media_type: getMediaType(fileObj.file),
                data: base64Data,
              },
            };
          }
          return item;
        }));

        contextForApi = contextForApi.map((msg, index) => {
          if (msg.role === 'user' && index !== contextForApi.length - 1) {
            return {
              ...msg,
              content: msg.content.filter(item => item.type !== "image_url")
            };
          }
          return index === contextForApi.length - 1 ? lastUserMessage : msg;
        });
      }

      // Add this code just before the console.log statement
      if (defaultSystemContext) {  // Use defaultSystemContext from UserContext
        const systemMessageIndex = contextForApi.findIndex(msg => msg.role === 'system');
        if (systemMessageIndex === -1) {
          // If there's no system message, add it at the beginning
          contextForApi.unshift({ role: 'system', content: defaultSystemContext.context });
        } else {
          // If there's already a system message, append to its content
          contextForApi[systemMessageIndex].content += '\n' + defaultSystemContext.context;
        }
      }

      console.log('apiContext to API endpoint:', contextForApi);

      setFiles([]);
      setUploadedFiles([]);

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/chat`, {
        model: selectedModel,
        context: contextForApi,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
        timeout: 30000 // 30 seconds timeout
      });

      console.log("raw response", response);
      const assistantContent = response.data.response_text;
      const inputTokens = response.data.input_tokens;
      const outputTokens = response.data.output_tokens;
      const inputCost = calculateCost(inputTokens, rates[selectedModel].input).toFixed(6);
      const outputCost = calculateCost(outputTokens, rates[selectedModel].output).toFixed(6);
      const assistantMessage = { role: 'assistant', content: assistantContent, inputTokens, outputTokens, inputCost, outputCost, model: selectedModel, temp: 'butthead' };
      const sentiment_flag = response.data?.sentiment_flag ?? null;
      const is_image_request = response.data?.is_image_request ?? null;
      const newTitleSummary = response.data?.session_title_summary ?? sessionTitle;
      setSessionTitleSummary(newTitleSummary);

      setContextWindow(prev => {
        const newContext = [...prev];
        const placeholderIndex = newContext.findIndex(msg => msg.temp === 'placeholder');
        newContext[placeholderIndex] = assistantMessage;
        return newContext;
      });

      setApiContext(prev => {
        const updatedApiContext = [...prev, assistantMessage];

        let totalTokens = inputTokens + outputTokens;
        checkAndSummarizeApiContext(totalTokens, updatedApiContext, contextWindow);

        return updatedApiContext;
      });
      
      const interaction = {
        inputTokens,
        outputTokens,
        inputCost,
        outputCost,
        model: selectedModel,
        user_content: contentStructure,
        assistant_content: assistantContent
      };

      await addInteraction(interaction);

      if (wasAudioInput && respondWithAudio) {
        await playTextToSpeech(assistantContent, 'random');
      }

      setIsLoading(false);
      setIsSendButtonDisabled(false); 
      setIsRecording(false);
      setIsTranscribing(false);
    } catch (error) {
      console.error('Error:', error);
      
      // Reset state
      setContextWindow(prev => prev.filter(msg => msg.temp !== 'placeholder'));
      setApiContext(prev => prev.filter(msg => msg.temp !== 'butthead'));
      setCurrentQuery(queryToSend);

      setIsLoading(false);
      setIsRecording(false);
      setIsTranscribing(false); 
      setIsSendButtonDisabled(false); 
    }
  };

  return (
    <div className="chat-container">
      <div className="chat-header">
        <div className={`chat-nav`}>
          <FaPlus className="new-chat-icon" onClick={handleNewChat} />
          <BsCardText className="context-icon" onClick={openSystemContextModal} />
          <FaHistory className="history-icon" onClick={() => navigate('/history')} />
          <FaCog className="settings-icon" onClick={() => navigate('/settings')} />
        </div>
        {isEditingTitle ? (
          <input
            type="text"
            value={sessionTitle}
            onChange={handleTitleChange}
            onBlur={handleTitleBlur}
            autoFocus
          />
        ) : (
          <h2 onClick={handleTitleClick}>{sessionTitle}</h2>
        )}
        <div className="header-metrics">
          <span className="total-tokens">Session Total Tokens: {totalTokens}</span>
          <span className="total-cost">Session Total Cost: ${sessionTotalCost.toFixed(2)}</span>
        </div>
      </div>
      <div className="chat-messages">
        {groupMessages().map((group, index) => (
          <div key={index} className="message-group">
            {group.map((item, i) => (
              <div key={i} className={`message ${item.role === 'user' ? 'user-message' : 'ai-message'}`}>
                <div className="message-content">
                  {renderMessageContent(item.content)}
                </div>
                {item.role === 'assistant' && (
                  <div className="message-meta">
                    <div className="meta-info">
                      <span>Input Tokens: {item.inputTokens} (${item.inputCost})</span>
                      <span>Output Tokens: {item.outputTokens} (${item.outputCost})</span>
                      <span>Model: {item.model}</span>
                    </div>
                    <FaTrash className="delete-icon" onClick={() => handleDeleteInteraction(item.id)} />
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="chat-prompt">
        <div
          className={`input-container ${dragOver ? 'drag-over' : ''}`}
          onDragOver={(e) => {
            e.preventDefault();
            setDragOver(true);
          }}
          onDragLeave={() => setDragOver(false)}
          onDrop={(e) => {
            e.preventDefault();
            handleFileDrop(e.dataTransfer.files);
          }}
        >
          <div className="icons-container">
            <label htmlFor="fileInput" className="file-input-label">
              <FaPaperclip />
            </label>
            <input
              type="file"
              id="fileInput"
              multiple
              style={{ display: 'none' }}
              onChange={handleFileChange}
              disabled={usageLimitCondition || isLoading || isSummarizing || isRecording}
            />
            <button 
              className={`recording-button ${isRecording ? 'stop' : ''} ${isTranscribing ? 'transcribing' : ''}`}
              onMouseDown={(e) => {
                e.preventDefault();
                if (!isTranscribing) {
                  isRecording ? handleStopRecording() : handleStartRecording();
                }
              }}
            >{isRecording ? <FaStop /> : <FaMicrophone />}</button>
            <VoiceRecorder ref={voiceRecorderRef} 
              showUI={false} 
              setIsRecording={setIsRecording}
              setIsTranscribing={setIsTranscribing}
              isRecording={isRecording}
              handleStopRecording={handleStopRecording} 
            />
          </div>
          
          <div className="input-wrapper">
            <div className="file-previews">
              {files.map((file, index) => (
                <div key={index} className="file-preview">
                  <img src={URL.createObjectURL(file)} alt="Preview" title={file.name} />
                  <button className="remove-btn" onClick={() => handleRemoveFile(index)}>x</button>
                </div>
              ))}
            </div>
            <textarea
              placeholder={
                usageLimitCondition 
                  ? (anonUsageLimitCondition 
                      ? 'You have reached the anonymous user limit. Please upgrade your account to continue using the app.' 
                      : 'You have reached the usage $ limit. Please contact Uncle Irvin to reset.'
                    ) 
                  : isLoading 
                    ? 'Waiting for response...' 
                    : isSummarizing 
                      ? 'Updating context, please wait...' 
                      : isRecording
                        ? 'Recording in progress...'
                        : 'Type your message...'
              }
              rows={3}
              value={currentQuery}
              onChange={handleQueryChange}
              onKeyDown={handleKeyDown}
              disabled={usageLimitCondition || isLoading || isSummarizing || isRecording}
            />
          </div>
        </div>

        <div className="checkbox-container">
          <input
            type="checkbox"
            id="respondWithAudio"
            checked={respondWithAudio}
            onChange={() => setRespondWithAudio(!respondWithAudio)}
          />
          <label htmlFor="respondWithAudio">Respond with audio to voice input</label>
        </div>

        <div className="chat-controls">
          <select value={selectedModel} onChange={handleModelChange} disabled={isLoading || isSummarizing || isRecording}>
            {Object.keys(rates).map((key) => (
              <option key={key} value={key}>{rates[key].alias}</option>
            ))}
          </select>
          <button 
            onClick={handleButtonClick} 
            onTouchStart={handleButtonClick}
            disabled={usageLimitCondition || isLoading || isSummarizing || isRecording}
          >
            Send
          </button>
        </div>
      </div>
      <ToastContainer position="bottom-center" />
      <SystemContextModal 
        isOpen={isSystemContextModalOpen} 
        onClose={closeSystemContextModal} 
      />
    </div>
  );
};

export default Chat;
