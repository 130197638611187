import React, { useState, useRef, useImperativeHandle, forwardRef, useEffect } from 'react';
import axios from 'axios';
import MicRecorder from 'mic-recorder-to-mp3';

const VoiceRecorder = forwardRef(({ showUI = false, setIsRecording, setIsTranscribing, isRecording, handleStopRecording }, ref) => {
  const [audioUrl, setAudioUrl] = useState(null);
  const [logs, setLogs] = useState(""); // State to store logs
  const recorder = useRef(new MicRecorder({ bitRate: 128 }));
  const recordedBlob = useRef(null); // Ref to store the recorded blob
  const silenceThreshold = -40; // Raised threshold
  const silenceDurationMs = 2000; // 2 seconds
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const silenceStartRef = useRef(null);
  const silenceDurationRef = useRef(0);
  const hasStoppedRecording = useRef(false); // Flag to indicate if recording has stopped

  useImperativeHandle(ref, () => ({
    startRecording,
    stopRecording,
    getTranscription,
    getAudioUrl: () => audioUrl
  }));

  const checkSilenceIntervalRef = useRef(null);

  useEffect(() => {
    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
      if (checkSilenceIntervalRef.current) {
        clearInterval(checkSilenceIntervalRef.current);
      }
    };
  }, []);

  const logMessage = (message) => {
    console.log(message);
    setLogs((prevLogs) => `${prevLogs}\n${message}`);
  };

  const resetSilenceDetection = () => {
    if (checkSilenceIntervalRef.current) {
      clearInterval(checkSilenceIntervalRef.current);
      checkSilenceIntervalRef.current = null;
    }
    silenceStartRef.current = null;
    silenceDurationRef.current = 0;
  };

  const startRecording = () => {
    logMessage('startRecording called');
    hasStoppedRecording.current = false; // Reset the flag
    recorder.current.start()
      .then(() => {
        setIsRecording(true);
        logMessage('Recording started');
        setupSilenceDetection();
      })
      .catch(error => logMessage(`Error accessing audio devices: ${error}`));
  };

  const setupSilenceDetection = () => {
    logMessage('Setting up silence detection');
    try {
      const stream = recorder.current.activeStream;
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
      analyserRef.current = audioContextRef.current.createAnalyser();
      const source = audioContextRef.current.createMediaStreamSource(stream);
      source.connect(analyserRef.current);
      analyserRef.current.fftSize = 2048;
      const bufferLength = analyserRef.current.fftSize;
      const dataArray = new Uint8Array(bufferLength);

      resetSilenceDetection(); // Ensure previous interval is cleared

      checkSilenceIntervalRef.current = setInterval(() => {
        if (hasStoppedRecording.current) return; // Exit if recording has stopped

        analyserRef.current.getByteTimeDomainData(dataArray);
        let sum = 0;
        for (let i = 0; i < bufferLength; i++) {
          const amplitude = (dataArray[i] - 128) / 128;
          sum += amplitude * amplitude;
        }
        const rms = Math.sqrt(sum / bufferLength);
        const db = 20 * Math.log10(rms);

        logMessage(`Current dB level: ${db.toFixed(2)} (Threshold: ${silenceThreshold})`);

        if (db < silenceThreshold) {
          if (silenceStartRef.current === null) {
            silenceStartRef.current = Date.now();
            silenceDurationRef.current = 0;
            logMessage(`Silence started at: ${silenceStartRef.current}`);
          } else {
            const silenceDurationElapsed = Date.now() - silenceStartRef.current;
            silenceDurationRef.current += 100;
            logMessage(`Silence duration elapsed: ${silenceDurationRef.current}ms`);
            if (silenceDurationRef.current >= silenceDurationMs) {
              logMessage('Silence threshold reached, stopping recording');
              handleStopRecording();  // Triggering handleStopRecording 
            }
          }
        } else {
          if (silenceStartRef.current !== null) {
            logMessage('Silence ended, resetting silenceStartRef');
            silenceStartRef.current = null;
            silenceDurationRef.current = 0;
          }
        }
      }, 100); // Check every 100ms
    } catch (error) {
      logMessage(`Failed to set up audio analysis: ${error}`);
    }
  };

  const stopRecording = () => {
    return new Promise((resolve, reject) => {
      logMessage('stopRecording called');
      hasStoppedRecording.current = true; // Set the flag to indicate recording has stopped
      if (checkSilenceIntervalRef.current) {
        clearInterval(checkSilenceIntervalRef.current); // Clear the silence detection interval
        checkSilenceIntervalRef.current = null;
      }
      recorder.current.stop()
        .getMp3()
        .then(([buffer, blob]) => {
          const audioBlob = new Blob(buffer, { type: 'audio/mp3' });
          recordedBlob.current = audioBlob;
          const newAudioUrl = URL.createObjectURL(audioBlob);
          setAudioUrl(newAudioUrl);
          logMessage(`Recording completed. Total audio size: ${audioBlob.size} bytes`);
          setIsRecording(false);
          internalHandleStopRecording(audioBlob);
          resolve();
        })
        .catch(error => {
          logMessage(`Error stopping recording: ${error}`);
          setIsTranscribing(false); // Reset transcribing state in case of error
          reject(error);
        });
    });
  };

  const internalHandleStopRecording = (audioBlob) => {
    const newAudioUrl = URL.createObjectURL(audioBlob);
    setAudioUrl(newAudioUrl);
    logMessage(`Recording completed. Total audio size: ${audioBlob.size} bytes`);
    logMessage(`Actual MIME type of recorded audio: ${audioBlob.type}`);
    // No need to call handleStopRecording here as it leads to an infinite loop
  };

  const getTranscription = async () => {
    const audioBlob = recordedBlob.current;
    logMessage(`Sending audio for transcription. Blob size: ${audioBlob.size} bytes`);

    if (audioBlob.size === 0) {
      logMessage("Audio blob is empty. Aborting transcription request.");
      return null;
    }

    const formData = new FormData();
    formData.append('file', audioBlob, 'recording.mp3');

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/transcribe`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        const transcription = response.data.transcription;
        logMessage(`Transcription received: ${transcription}`);
        setIsTranscribing(false); // Reset transcribing state here
        return transcription;
      } else {
        logMessage(`Failed to transcribe audio: ${response.status} - ${response.statusText}`);
        setIsTranscribing(false); // Reset transcribing state here in case of error
        return null;
      }
    } catch (error) {
      logMessage(`Error transcribing audio: ${error.response ? error.response.data : error.message}`);
      setIsTranscribing(false); // Reset transcribing state here in case of error
      return null;
    }
  };

  return (
    <div>
      {showUI && audioUrl && (
        <div>
          <audio src={audioUrl} controls />
          <p>Recorded audio size: {recordedBlob.current ? recordedBlob.current.size : 0} bytes</p>
        </div>
      )}
      {showUI && (
        <div>
          <textarea readOnly value={logs} style={{ width: '100%', height: '200px' }} />
        </div>
      )}
    </div>
  );
});

export default VoiceRecorder;
