// components/TextToSpeech.js
import axios from 'axios';

const voices = ["alloy", "echo", "fable", "onyx", "nova", "shimmer"];

const getRandomVoice = () => {
  const randomIndex = Math.floor(Math.random() * voices.length);
  return voices[randomIndex];
};

const playTextToSpeech = async (text, voice = 'nova') => {
  const selectedVoice = voice === 'random' ? getRandomVoice() : voice;

  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/tts`, {
      text: text,
      voice: selectedVoice
    }, {
      responseType: 'blob'
    });

    if (response.status === 200) {
      const audioBlob = response.data;
      const audioUrl = URL.createObjectURL(audioBlob);
      const audio = new Audio(audioUrl);
      audio.play();
      
      console.log("selected Voice: ", selectedVoice);
    
      // Cleanup after audio ends
      audio.onended = () => {
        URL.revokeObjectURL(audioUrl);
      };
    } else {
      console.error("Failed to fetch audio:", response.status, response.statusText);
    }
  } catch (error) {
    console.error("Error fetching audio:", error);
  }
};

export default playTextToSpeech;
