import React, { createContext } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyDZV8Pa5n_1zUk6S86CjsQzoXA6zV8b--E',
  authDomain: 'unifai-chat.firebaseapp.com',
  projectId: 'unifai-chat',
  storageBucket: 'unifai-chat.appspot.com',
  messagingSenderId: '777448298487',
  appId: '1:777448298487:web:1283a4ab9fdc4b3a5680f2',
  measurementId: 'G-E9D2S2K1X6',
};

// Initialize Firebase
const app       = initializeApp(firebaseConfig);
const db        = getFirestore(app);
const analytics = getAnalytics(app);
const auth      = getAuth(app);
const storage   = getStorage(app);

export const FirebaseContext  = createContext();

export const FirebaseProvider = ({ children }) => {
  return (
    <FirebaseContext.Provider value={{ db, analytics, auth, storage }}>
      {children}
    </FirebaseContext.Provider>
  );
};