import { useState, useEffect, useRef, useCallback } from 'react';

const useTextSelection = (selector, onWordSelectionComplete, onSentenceSelectionComplete) => {
    const [isDragging, setIsDragging] = useState(false);
    const [selectedWords, setSelectedWords] = useState(new Set());
    const longPressTimeout = useRef(null);

    const getTextNodes = useCallback((node) => {
        let textNodes = [];
        if (node.nodeType === 3) {
            textNodes.push(node);
        } else {
            for (const child of node.childNodes) {
                textNodes = textNodes.concat(getTextNodes(child));
            }
        }
        return textNodes;
    }, []);

    const getWordAtPosition = useCallback((element, x, y) => {
        const range = document.caretRangeFromPoint(x, y);
        if (range) {
            const text = element.innerText || element.textContent;
            const words = text.split(/\s+/);
            if (words) {
                for (const word of words) {
                    if (range.startOffset >= text.indexOf(word) && range.startOffset <= text.indexOf(word) + word.length) {
                        return word.trim();
                    }
                }
            }
        }
        return null;
    }, []);

    const getSentenceAtPosition = useCallback((element, x, y) => {
        const range = document.caretRangeFromPoint(x, y);
        if (range) {
            const text = element.innerText || element.textContent;
            const sentenceRegex = /[^.!?]+[.!?]["'"]?/g;
            const sentences = text.match(sentenceRegex);
            if (sentences) {
                for (const sentence of sentences) {
                    if (range.startOffset >= text.indexOf(sentence) && range.startOffset <= text.indexOf(sentence) + sentence.length) {
                        return sentence.trim();
                    }
                }
            }
        }
        return null;
    }, []);

    const highlightWords = useCallback((words) => {
        const textNodes = getTextNodes(document.body);
        const selection = window.getSelection();
        selection.removeAllRanges();

        let startNode = null;
        let startOffset = 0;
        let endNode = null;
        let endOffset = 0;

        words.forEach((word, index) => {
            for (const node of textNodes) {
                const nodeText = node.nodeValue;
                const wordIndex = nodeText.indexOf(word);
                
                if (wordIndex !== -1) {
                    if (startNode === null) {
                        startNode = node;
                        startOffset = wordIndex;
                    }
                    endNode = node;
                    endOffset = wordIndex + word.length;
                    
                    if (index < words.length - 1 && nodeText.indexOf(words[index + 1]) === -1) {
                        break;
                    }
                }
            }
        });

        if (startNode && endNode) {
            const range = document.createRange();
            range.setStart(startNode, startOffset);
            range.setEnd(endNode, endOffset);
            selection.addRange(range);
        }
    }, [getTextNodes]);

    const highlightSentence = useCallback((sentence) => {
        const textNodes = getTextNodes(document.body);
        for (const node of textNodes) {
            if (node.nodeValue.includes(sentence)) {
                const index = node.nodeValue.indexOf(sentence);
                const range = document.createRange();
                range.setStart(node, index);
                range.setEnd(node, index + sentence.length);
                const selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(range);
                break;
            }
        }
    }, [getTextNodes]);

    useEffect(() => {
        const handleMouseUp = () => {
            clearTimeout(longPressTimeout.current);
            if (isDragging) {
                const selectedText = Array.from(selectedWords).join(' ');
                if (selectedText) {
                    onWordSelectionComplete(selectedText, 'words');
                    highlightWords(Array.from(selectedWords));
                }
                setIsDragging(false);
                setSelectedWords(new Set());
            }
            longPressTimeout.current = null;
        };

        const handleMouseDown = (e) => {
            if (!e.target.closest(selector)) return; // Only handle events within the specified selector
            setIsDragging(false);
            clearTimeout(longPressTimeout.current);
            longPressTimeout.current = setTimeout(() => {
                const startWord = getWordAtPosition(e.target, e.clientX, e.clientY);
                if (startWord) {
                    setSelectedWords(new Set([startWord]));
                    highlightWords([startWord]);
                }
                setIsDragging(true);
                longPressTimeout.current = null;
            }, 200);
        };

        const handleMouseMove = (e) => {
            if (isDragging && e.target.closest(selector)) {
                const endWord = getWordAtPosition(e.target, e.clientX, e.clientY);
                if (endWord && !selectedWords.has(endWord)) {
                    setSelectedWords(prev => new Set([...prev, endWord]));
                    highlightWords(Array.from(selectedWords).concat(endWord));
                }
            }
        };

        const handleMouseClick = (e) => {
            if (!e.target.closest(selector)) return; // Only handle events within the specified selector
            if (window.getSelection().toString() === '') {
                const sentence = getSentenceAtPosition(e.target, e.clientX, e.clientY);
                if (sentence) {
                    highlightSentence(sentence);
                    onSentenceSelectionComplete(sentence, 'sentence');
                }
            }
        };

        document.addEventListener('mouseup', handleMouseUp);
        document.addEventListener('mousedown', handleMouseDown);
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('click', handleMouseClick);

        return () => {
            document.removeEventListener('mouseup', handleMouseUp);
            document.removeEventListener('mousedown', handleMouseDown);
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('click', handleMouseClick);
        };
    }, [isDragging, selectedWords, getWordAtPosition, getSentenceAtPosition, highlightWords, highlightSentence, onWordSelectionComplete, onSentenceSelectionComplete, selector]);

    return {
        isDragging,
        selectedWords: Array.from(selectedWords),
    };
};

export default useTextSelection;
