import React from 'react';
import { FirebaseProvider } from './contexts/Firebase';
import { ChatProvider } from './contexts/Chat';
import { UserProvider } from './contexts/User';
import AppRouter from './AppRouter';

const App = () => {
  return (
    <FirebaseProvider>
      <UserProvider>
        <ChatProvider>
          <AppRouter />
        </ChatProvider>
      </UserProvider>
    </FirebaseProvider>
  );
};

export default App;
