import React from 'react';
import Lottie from 'react-lottie';
import animationData1 from '../images/lottie_loading1.json';
import animationData2 from '../images/lottie_loading2.json';
import animationData3 from '../images/lottie_loading3.json';
import animationData4 from '../images/lottie_loading4.json';
import animationData5 from '../images/lottie_loading5.json';
import animationData6 from '../images/lottie_loading6.json';
import animationData7 from '../images/lottie_loading7.json';
import animationData8 from '../images/lottie_loading8.json';
import animationData9 from '../images/lottie_loading9.json';

import animationData10 from '../images/lottie_singularity.json';
import animationData11 from '../images/lottie_siri.json';
import animationData12 from '../images/lottie_infinity.json';
import animationData13 from '../images/lottie_drops.json';
import animationData14 from '../images/lottie_aroundtheworld.json';
import animationData15 from '../images/lottie_rocketloader.json';
import animationData16 from '../images/lottie_loading_gameboy.json';
import animationData17 from '../images/lottie_nativeskull.json';
import animationData18 from '../images/lottie_oldbboy.json';

import animationData19 from '../images/lottie_desoleil.json';
import animationData20 from '../images/lottie_cat.json';
import animationData21 from '../images/lottie_hamster.json';
import animationData22 from '../images/lottie_bear.json';
import animationData23 from '../images/lottie_boxdog.json';
import animationData24 from '../images/lottie_dogramen.json';
import animationData25 from '../images/lottie_cathanggliding.json';
import animationData26 from '../images/lottie_catloader.json';
import animationData27 from '../images/lottie_spacemonkey.json';

import animationData28 from '../images/lottie_bookwriting.json';
import animationData29 from '../images/lottie_bookexchange.json';
import animationData30 from '../images/lottie_bookopening.json';
import animationData31 from '../images/lottie_bookpageturning.json';
import animationData32 from '../images/lottie_bookie.json';
import animationData33 from '../images/lottie_bookfantasy.json';

import animationData34 from '../images/lottie_uploadcloud.json';
import animationData35 from '../images/lottie_greencheck.json';
import animationData36 from '../images/lottie_playbutton.json';
import animationData37 from '../images/lottie_cardaccepted.json';
import animationData38 from '../images/lottie_carddeclined.json';

export const lottieAnimations = [
    animationData1, animationData2, animationData3, animationData4, animationData5, animationData6,
    animationData7, animationData8, animationData9, animationData10, animationData11, animationData12,
    animationData13, animationData14, animationData15, animationData16, animationData17, animationData18,
    animationData19, animationData20, animationData21, animationData22, animationData23, animationData24,
    animationData25, animationData26, animationData27, animationData28, animationData29, animationData30,
    animationData31, animationData32, animationData33 
];

export const getRandomAnimationData = () => {
    const randomIndex = Math.floor(Math.random() * lottieAnimations.length);
    return lottieAnimations[randomIndex];
};

export const defaultLottieOptions = (animationData) => ({
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
});

export const LottieAnimation = ({ animationData }) => {
    const { w: originalWidth, h: originalHeight } = animationData;

    const maxHeight = 200;
    const aspectRatio = originalWidth / originalHeight;
    const width = aspectRatio * maxHeight;
    const height = maxHeight;

    const options = {
        loop: true,
        autoplay: true,
        animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return <Lottie options={options} width={width} height={height} />;
};



/* 
DYNAMIC 

import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie';

const importAll = (r) => r.keys().map(r);
const animationContext = require.context('../images', false, /\.json$/);
const animationFiles = importAll(animationContext);

export const useLottieAnimations = () => {
  const [animations, setAnimations] = useState([]);

  useEffect(() => {
    const loadAnimations = async () => {
      const loadedAnimations = await Promise.all(
        animationFiles.map(async (key) => {
          const module = await import(`../images/${key.slice(2)}`);
          return module.default;
        })
      );
      setAnimations(loadedAnimations);
    };

    loadAnimations();
  }, []);

  return animations;
};

export const getRandomAnimationData = (animations) => {
  const randomIndex = Math.floor(Math.random() * animations.length);
  return animations[randomIndex];
};

export const defaultLottieOptions = (animationData) => ({
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
});

export const LottieAnimation = ({ animationData }) => {
  const { w: originalWidth, h: originalHeight } = animationData;

  const maxHeight = 200;
  const aspectRatio = originalWidth / originalHeight;
  const width = aspectRatio * maxHeight;
  const height = maxHeight;

  const options = defaultLottieOptions(animationData);

  return <Lottie options={options} width={width} height={height} />;
};

// Example usage in a component:
export const LottieAnimationWrapper = () => {
  const animations = useLottieAnimations();
  const randomAnimation = animations.length > 0 ? getRandomAnimationData(animations) : null;

  if (!randomAnimation) {
    return <div>Loading...</div>;
  }

  return <LottieAnimation animationData={randomAnimation} />;
};
*/